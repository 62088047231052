import axios, {AxiosResponse, AxiosPromise, AxiosRequestConfig, AxiosError} from 'axios';
import {Response, OpenShift, OpenShiftApplication, RawOpenShift, Employee, OAuthResponse} from '@/assets/MarktplaatsInterfaces';
import pkceChallenge from 'pkce-challenge';

export class MarketplaceAPIClient {
    private static accessToken?: string;
    private static username?: string;

    public static getAccessToken(): string | undefined {
        return MarketplaceAPIClient.accessToken;
    }

    public static setAccessToken(accessToken: string | undefined): void {
        MarketplaceAPIClient.accessToken = accessToken;
    }

    public static getUsername(): string | undefined {
        return MarketplaceAPIClient.username;
    }

    public static setUsername(username: string | undefined): void {
        MarketplaceAPIClient.username = username;
    }

    public static getOpenShifts(getActiveOnly: boolean = false): Promise<OpenShift[]> {
        return MarketplaceAPIClient.get<RawOpenShift[]>('api/shift', {getActiveOnly: getActiveOnly})
            .then((response: AxiosResponse<Response<RawOpenShift[]>>) => {
                return response.data.data.map(MarketplaceAPIClient.convertRawOpenShiftToOpenShift);
            });
    }

    public static getOpenShiftsHistory(): Promise<OpenShift[]> {
        return MarketplaceAPIClient.get<RawOpenShift[]>('api/shift', {getHistory: true})
            .then((response: AxiosResponse<Response<RawOpenShift[]>>) => {
                return response.data.data.map(MarketplaceAPIClient.convertRawOpenShiftToOpenShift);
            });
    }

    public static getSingleOpenShift(openShiftId: number): Promise<OpenShift> {
        return MarketplaceAPIClient.get<RawOpenShift>('api/shift/' + openShiftId)
            .then((response: AxiosResponse<Response<RawOpenShift>>) => {
                return MarketplaceAPIClient.convertRawOpenShiftToOpenShift(response.data.data);
            });
    }

    public static getOpenShiftApplications(openShiftId: number | null): Promise<OpenShiftApplication[]> {
        return MarketplaceAPIClient.get<OpenShiftApplication[]>('api/shift/' + openShiftId + '/applications')
            .then((response: AxiosResponse<Response<OpenShiftApplication[]>>) => {
                return response.data.data;
            });
    }

    public static getEmployeeByName(name: string): Promise<Employee | undefined> {
        return MarketplaceAPIClient.get<Employee[]>('api/employee', {name})
            .then((response: AxiosResponse<Response<Employee[]>>): Employee | undefined => {
                return response.data.data[0];
            });
    }

    public static getEmployeeIdBySubmittingEmployee(name: string, phone: string | null, email: string | null): Promise<number> {
        const postData: Record<string, string> = {name};
        if (phone !== null) {
            postData.phone = phone;
        }

        if (email !== null) {
            postData.email = email;
        }

        return MarketplaceAPIClient.post<number>('api/employee', postData)
            .then((response: AxiosResponse<Response<number>>) => {
                return Number(response.headers.location.split('/').pop());
            });
    }

    public static postOpenShiftApplication(openShiftId: number, employeeId: number, comment: string | null): Promise<void> {
        return MarketplaceAPIClient.post<void>('api/shift/application', {openShiftId, employeeId, comment})
            .then(() => {
                return;
            });
    }

    public static withdrawApplication(applicationId: number): Promise<void> {
        return MarketplaceAPIClient.post<void>('api/shift/application/' + applicationId + '/withdraw', {})
            .then(() => {
                return;
            });
    }

    public static logout(): void {
        localStorage.removeItem('username');

        const searchParams: URLSearchParams = new URLSearchParams();
        searchParams.append('redirect_uri', ENV_CONFIG.CALLBACK_BASE + 'login');
        window.location.href = ENV_CONFIG.MARKETPLACE_HOST + 'logout?' + searchParams.toString();
    }

    public static changePassword(oldPassword: string | null, newPassword: string | null): Promise<string | void> {
        return MarketplaceAPIClient.post<void>('api/change-password', {oldPassword, newPassword})
            .then(() => {
                return;
            }).catch((error: AxiosError) => {
                let errors: string = '';
                if (error.response?.data.errors) {
                    error.response?.data.errors.newPassword.forEach((e: string) => {
                        errors += e + '\n';
                    });
                } else {
                    errors += error.response?.data.message + '\n';
                }

                return Promise.reject(errors);
            });
    }

    public static marketplaceLogin(): void {
        const searchParams: URLSearchParams = new URLSearchParams();
        searchParams.append('redirect_uri', ENV_CONFIG.CALLBACK_BASE + 'redirect');
        window.location.href = ENV_CONFIG.MARKETPLACE_HOST + 'login?' + searchParams.toString();
    }

    public static marketplaceRedirect(): void {
        const urlParams: URLSearchParams = new URLSearchParams(window.location.search);

        if (urlParams.get('username')) {
            localStorage.setItem('username', urlParams.get('username') as string);
        }

        const pkce: { code_challenge: string; code_verifier: string } = pkceChallenge();
        localStorage.setItem('marktplaats_verifier', pkce.code_verifier);

        const searchParams: URLSearchParams = new URLSearchParams();
        searchParams.append('client_id', String(ENV_CONFIG.MARKETPLACE_API_CLIENT_ID));
        searchParams.append('redirect_uri', ENV_CONFIG.CALLBACK_BASE + 'auth/callback');
        searchParams.append('response_type', 'code');
        searchParams.append('scope', 'is-supplier');
        searchParams.append('code_challenge', pkce.code_challenge);
        searchParams.append('code_challenge_method', 'S256');
        window.location.href = ENV_CONFIG.MARKETPLACE_HOST + 'oauth/authorize?' + searchParams.toString();
    }

    public static marketplaceCallback(): Promise<boolean> {
        const urlParams: URLSearchParams = new URLSearchParams(window.location.search);

        const postData: Record<string, string | number | null> = {
            'grant_type': 'authorization_code',
            'client_id': ENV_CONFIG.MARKETPLACE_API_CLIENT_ID,
            'redirect_uri': ENV_CONFIG.CALLBACK_BASE + 'auth/callback',
            'code_verifier': localStorage.getItem('marktplaats_verifier'),
            'code': urlParams.get('code')
        };

        return MarketplaceAPIClient.post<OAuthResponse>('oauth/token', postData)
            .then((response: AxiosResponse<OAuthResponse>) => {
                localStorage.removeItem('marktplaats_verifier');
                this.username = localStorage.getItem('username') as string;
                MarketplaceAPIClient.setAccessToken(response.data.access_token);
                return true;
            }).catch(() => {
                return false;
            });
    }

    private static convertRawOpenShiftToOpenShift(raw: RawOpenShift): OpenShift {
        return {
            ...raw,
            timeBegin: new Date(raw.timeBegin),
            timeEnd: new Date(raw.timeEnd),
        };
    }

    private static get<T>(url: string, params?: Record<string, unknown>): AxiosPromise {
        const config: AxiosRequestConfig = {};
        MarketplaceAPIClient.handleAuthorization(config);
        config.params = params;

        return axios.get(ENV_CONFIG.MARKETPLACE_HOST + url, config)
            .then((response: AxiosResponse<Response<T>>) => {
                return response;
            });
    }

    private static post<T>(url: string, data: Record<string, unknown>): AxiosPromise {
        const config: AxiosRequestConfig = {};
        MarketplaceAPIClient.handleAuthorization(config);

        return axios.post(ENV_CONFIG.MARKETPLACE_HOST + url, data, config)
            .then((response: AxiosResponse<Response<T>>) => {
                return response;
            });
    }

    private static put<T>(url: string, data?: Record<string, unknown>): AxiosPromise {
        const config: AxiosRequestConfig = {};
        MarketplaceAPIClient.handleAuthorization(config);

        return axios.put(ENV_CONFIG.MARKETPLACE_HOST + url, data, config)
            .then((response: AxiosResponse<Response<T>>) => {
                return response;
            });
    }

    private static handleAuthorization(config: AxiosRequestConfig): AxiosRequestConfig {
        if (MarketplaceAPIClient.getAccessToken()) {
            config.headers = {'Authorization': `Bearer ${MarketplaceAPIClient.getAccessToken()}`};
        }

        return config;
    }
}