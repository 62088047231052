<template>
    <div></div>
</template>

<script lang="ts">
    import {Vue, Component} from 'vue-property-decorator';
    import {MarketplaceAPIClient} from '@/util/MarketplaceAPIClient';

    @Component({})
    export default class Callback extends Vue {
        created(): void {
            MarketplaceAPIClient.marketplaceCallback().then((success: boolean) => {
                if (success) {
                    const url: string | null = localStorage.getItem('lastVisited');
                    if (url !== null) {
                        this.$router.push(url);
                    } else {
                        this.$router.push({name: 'open-shift-overview'});
                    }
                } else {
                    this.$router.push({name: 'login'});
                }
            });
        }
    }
</script>

<style scoped lang="scss">

</style>