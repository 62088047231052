import { render, staticRenderFns } from "./ShiftOverview.vue?vue&type=template&id=01fcc520&scoped=true"
import script from "./ShiftOverview.vue?vue&type=script&lang=ts"
export * from "./ShiftOverview.vue?vue&type=script&lang=ts"
import style0 from "./ShiftOverview.vue?vue&type=style&index=0&id=01fcc520&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01fcc520",
  null
  
)

export default component.exports