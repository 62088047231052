<template>
    <div class="d-flex flex-column justify-content-center open-shift-container" v-if="openShift">
        <h6><a href="#" @click.prevent="$router.push({name: 'open-shift-overview'})">&larr;Terug naar overzicht</a></h6>

        <cq-notifications :notifications="notifications" />

        <cq-widget>
            <h4>Informatie van de aanvraag</h4>
            <table class="table">
                <tbody>
                    <tr>
                        <th>Status</th>
                        <td>{{ StateUtil.SHIFT_STATE_LABELS[openShift.state] }}</td>
                    </tr>
                    <tr>
                        <th>Functie</th>
                        <td>{{ openShift.function }}</td>
                    </tr>
                    <tr>
                        <th>Locatie</th>
                        <td>{{ openShift.location }}</td>
                    </tr>
                    <tr>
                        <th>Datum</th>
                        <td>{{ getBeginDate(openShift) }}</td>
                    </tr>
                    <tr>
                        <th>Tijd</th>
                        <td>{{ getTimeRange(openShift) }}</td>
                    </tr>
                    <tr v-if="openShift.comment">
                        <th>Opmerking</th>
                        <td>{{ openShift.comment }}</td>
                    </tr>
                    <tr v-if="openShift.contact">
                        <th>Contactpersoon</th>
                        <td>Voor vragen kunt u contact opnemen met {{ openShift.contact }}</td>
                    </tr>
                </tbody>
            </table>
        </cq-widget>

        <cq-widget>
            <h4>Aangemelde uitzendkracht(en)</h4>
            <p v-if="openShiftApplications && openShiftApplications.length === 0">
                <span>Geen ingediende aanmeldingen</span>
            </p>

            <table class="table table-sm" v-else-if="openShiftApplications">
                <thead>
                    <tr>
                        <th>Naam</th>
                        <th>Telefoon</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Reden afkeuring</th>
                        <th>Actie</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="application in openShiftApplications" :key="application.id" class="testclass">
                        <td v-bind:class="applicationState(application)">{{ application.employee.name }}</td>
                        <td v-bind:class="applicationState(application)">{{ application.employee.phone }}</td>
                        <td v-bind:class="applicationState(application)">{{ application.employee.email }}</td>
                        <td v-bind:class="applicationState(application)">{{ StateUtil.APPLICATION_STATE_LABELS[application.state] }}</td>
                        <td v-bind:class="applicationState(application)">{{ application.rejectReason }}</td>
                        <td v-bind:class="applicationState(application)">
                            <a v-if="isRegistered(application)" href="#" @click.prevent="withdrawApplication(application)" class="clickable">Intrekken</a>
                            <span v-else>Geen actie</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <cq-button v-if="canCreateApplication(openShift)" class="float-right" :primary="true" label="Uitzendkracht aanmelden" type="button" shape="normal" margin="normal" size="md" :centered="false" @click="openModal" />
            <span v-else><b>Geen aanmeldingen meer mogelijk.</b></span>
        </cq-widget>

        <div> <!-- Application modal -->
            <transition name="modal-background" appear>
                <div class="modal-overlay" v-if="showModal" @click="closeModal"></div>
            </transition>
            <transition name="modal-application" appear>
                <div class="modal-application" v-if="showModal">
                    <h4>Uitzendkracht aanmelden</h4>
                    <form @submit.prevent="handleNewOpenShiftApplication">
                        <cq-text-input
                            id="employeeName"
                            type="text"
                            v-model="employeeName"
                            label="Naam van uitzendkracht"
                            invalidFeedback="Voer een correcte waarde in"
                            pattern="[a-zA-Z0-9 \-]+"
                            required
                            @input="handleNameChange"
                            @invalidInput="enableSend=false; enableInput=false"
                        />
                        <cq-text-input
                            id="employeePhone"
                            type="tel"
                            v-model="phone"
                            label="Telefoonnummer"
                            invalidFeedback="Voer alleen numerieke waardes in"
                            pattern="\+?(\d+(\s|-))*\d+"
                            :readonly="!enableInput"
                            @validInput="enableSend=true"
                            @invalidInput="enableSend=false"
                        />
                        <cq-text-input
                            id="employeeEmail"
                            type="email"
                            v-model="email"
                            label="E-mail"
                            invalidFeedback="Voer een geldig e-mailadres in"
                            :readonly="!enableInput"
                            @validInput="enableSend=true"
                            @invalidInput="enableSend=false"
                        />
                        <cq-text-input
                            id="employeeComment"
                            type="text"
                            v-model="employeeComment"
                            label="Opmerking" />
                        <cq-button
                            label="Aanmelding versturen"
                            class="float-right no-gutters"
                            type="submit"
                            shape="normal"
                            margin="normal"
                            size="md"
                            :centered="false"
                            :disabled="!enableSend || isSending" />
                    </form>
                </div>
            </transition>
        </div>
    </div>
</template>

<script lang="ts">
    import {Vue, Component} from 'vue-property-decorator';
    import {Employee, OpenShift, OpenShiftApplication} from '@/assets/MarktplaatsInterfaces';
    import {CqButton, CqTextInput, CqWidget, CqNotifications} from '@cquential/ui';
    import {MarketplaceAPIClient} from '@/util/MarketplaceAPIClient';
    import {AxiosError} from 'axios';
    import {ApplicationState} from '@/types/enums/ApplicationState';
    import {ShiftState} from '@/types/enums/ShiftState';
    import {UserNotification} from '@cquential/ui/types/notifications';
    import StateUtil from '@/util/StateUtil';

    @Component({
        components: {
            CqButton,
            CqTextInput,
            CqWidget,
            CqNotifications,
        }
    })
    export default class OpenShiftView extends Vue {
        private readonly RETRIEVE_EMPLOYEE_DATA_TIMEOUT: number = 500;
        private readonly HIDE_MODAL_ERROR_TIMEOUT: number = 5000;

        private openShift: OpenShift | undefined | null = null;
        private openShiftId: number | null = null;

        private openShiftApplications: OpenShiftApplication[] | null | undefined = null;

        private showModal: boolean | null = false;
        private employeeName: string = '';
        private employeePhone: string | null = null;
        private employeeEmail: string | null = null;
        private employeeComment: string = '';

        private isSending: boolean = false;
        private enableSend: boolean = false;
        private enableInput: boolean = false;
        private timeout?: number = undefined;
        private notifications: UserNotification[] = [];

        public get StateUtil(): typeof StateUtil {
            return StateUtil;
        }

        private get phone(): string {
            return this.employeePhone ?? '';
        }

        private set phone(value: string) {
            this.employeePhone = value;
        }

        private get email(): string {
            return this.employeeEmail ?? '';
        }

        private set email(value: string) {
            this.employeeEmail = value;
        }

        mounted(): void {
            if (this.$route.params.openShift != undefined) {
                this.openShift = JSON.parse(this.$route.params.openShift) as unknown as OpenShift;
                this.openShift.timeBegin = new Date(this.openShift.timeBegin);
                this.openShift.timeEnd = new Date(this.openShift.timeEnd);
            }
            this.openShiftId = parseInt(this.$route.params.id);

            if (!this.openShift) {
                this.fetchOpenShift(this.openShiftId);
            }

            MarketplaceAPIClient.getOpenShiftApplications(this.openShiftId).then((response: OpenShiftApplication[]) => {
                this.openShiftApplications = response;
            });
        }

        private fetchOpenShift(openShiftId: number): void {
            MarketplaceAPIClient.getSingleOpenShift(openShiftId).then((response: OpenShift): void => {
                this.openShift = response;
                if (this.openShift) {
                    this.openShift.timeBegin = new Date(response.timeBegin);
                    this.openShift.timeEnd = new Date(response.timeEnd);
                }
            }).catch((): void => {
                this.$router.push({name: 'open-shift-overview'});
            });
        }

        private isRegistered(application: OpenShiftApplication): boolean {
            return application.state === ApplicationState.REGISTERED;
        }

        private applicationState(application: OpenShiftApplication): string {
            switch (application.state) {
                case ApplicationState.CHOSEN:
                    return 'application-accepted';
                case ApplicationState.WITHDRAWN:
                case ApplicationState.REJECTED:
                    return 'application-rejected';
                default:
                    return '';
            }
        }

        private employeeHasActiveApplication(employeeName: string): boolean {
            if (this.openShiftApplications) {
                const application: OpenShiftApplication | undefined = this.openShiftApplications.find((application: OpenShiftApplication): boolean => {
                    return application.employee.name === employeeName
                        && application.state === ApplicationState.REGISTERED;
                });
                if (application) {
                    this.notifications.push({type: 'error', text: `Medewerker met naam '${employeeName}' is al aangemeld.`});
                    return true;
                }
            }
            return false;
        }

        private handleNewOpenShiftApplication(): void {
            if (!this.enableSend || this.isSending || this.employeeHasActiveApplication(this.employeeName)) {
                return;
            }

            this.isSending = true;

            MarketplaceAPIClient.getEmployeeIdBySubmittingEmployee(this.employeeName, this.employeePhone, this.employeeEmail).then((employeeId: number): Promise<void> => {
                return MarketplaceAPIClient.postOpenShiftApplication(this.openShiftId!, employeeId, this.employeeComment).then((): void => {
                    MarketplaceAPIClient.getOpenShiftApplications(this.openShiftId!).then((response: OpenShiftApplication[]): void => {
                        this.openShiftApplications = response;
                        this.employeeName = '';
                        this.employeePhone = null;
                        this.employeeEmail = null;
                        this.employeeComment = '';
                        this.enableSend = false;
                        this.enableInput = true;
                    });
                    this.closeModal();
                }).catch((error: AxiosError): void => {
                    if (error.response?.status === 409) {
                        if (this.openShiftId) {
                            this.fetchOpenShift(this.openShiftId);
                        }
                    } else if (error.response?.status === 422) {
                        if (error.response?.data?.errors) {
                            const rawErrors: Record<string, string[]> = error.response.data.errors;
                            let message: string = '';
                            for (message of Object.values(rawErrors).flat()) {
                                this.notifications.push({type: 'error', text: message});
                            }
                        }
                        this.enableSend = true;
                    } else {
                        throw error;
                    }
                });
            }).finally((): void => {
                this.isSending = false;
            });
        }

        private withdrawApplication(application: OpenShiftApplication): void {
            MarketplaceAPIClient.withdrawApplication(application.id).then((): void => {
                MarketplaceAPIClient.getOpenShiftApplications(this.openShiftId).then((response: OpenShiftApplication[]): void => {
                    this.openShiftApplications = response;
                });
            });
        }

        private getTimeRange(shift: OpenShift): string {
            const timeBegin: string = shift.timeBegin.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'Europe/Amsterdam'
            });

            const timeEnd: string = shift.timeEnd.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'Europe/Amsterdam'
            });

            return (timeBegin + ' - ' + timeEnd);
        }

        private getBeginDate(openShift: OpenShift): string {
            return openShift.timeBegin.toLocaleDateString().replaceAll('/', '-');
        }

        private canCreateApplication(openShift: OpenShift): boolean {
            return (openShift.isActive && openShift.state === ShiftState.OPEN && openShift.timeBegin > new Date());
        }

        private handleNameChange(): void {
            this.enableSend = false;
            this.enableInput = false;

            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(this.retrieveEmployeeData, this.RETRIEVE_EMPLOYEE_DATA_TIMEOUT);
        }

        private retrieveEmployeeData(): void {
            if (this.employeeName.length > 2 && !this.employeeHasActiveApplication(this.employeeName)) {
                MarketplaceAPIClient.getEmployeeByName(this.employeeName).then((employee: Employee | undefined): void => {
                    this.employeePhone = employee?.phone ?? null;
                    this.employeeEmail = employee?.email ?? null;
                    this.enableSend = true;
                    this.enableInput = true;
                });
            }
        }

        private closeModal(): void {
            this.showModal = false;
        }

        private openModal(): void {
            this.showModal = true;
        }
    }
</script>

<style scoped lang="scss">
    .open-shift-container {
        min-width: 650px;
        padding-bottom: 15rem;
    }

    .modal-background-enter-active,
    .modal-background-leave-active,
    .modal-application-enter-active,
    .modal-application-leave-active {
        transition: opacity .4s;
    }

    .modal-background-enter,
    .modal-background-leave-to,
    .modal-application-enter,
    .modal-application-leave-to {
        opacity: 0;
    }

    .modal-application {
        position: fixed;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
        width: 100%;
        max-width: 400px;
        background-color: #ffffff;
        border-radius: 16px;
        padding: 25px;

        h4 {
            margin-bottom: 1rem;
        }
    }

    .modal-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 98;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .application-rejected {
        color: rgba(255, 43, 43, 1);
    }

    .application-accepted {
        color: rgba(13, 219, 48, 1);
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>