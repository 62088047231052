import {ShiftState} from '@/types/enums/ShiftState';
import {ApplicationState} from '@/types/enums/ApplicationState';

export default class StateUtil {
    public static readonly APPLICATION_STATE_LABELS: Record<ApplicationState, string> = {
        [ApplicationState.REGISTERED] : 'aangemeld',
        [ApplicationState.REJECTED] : 'afgekeurd',
        [ApplicationState.CHOSEN] : 'gekozen',
        [ApplicationState.WITHDRAWN] : 'ingetrokken',
    };

    public static readonly SHIFT_STATE_LABELS: Record<ShiftState, string> = {
        [ShiftState.OPEN] : 'open',
        [ShiftState.FILLED] : 'ingevuld',
        [ShiftState.WITHDRAWN] : 'ingetrokken',
    };
}
