<template>
    <table class="table" v-if="shifts && shifts.length !== 0">
        <thead>
            <tr>
                <th>Functie</th>
                <th>Locatie</th>
                <th>Datum</th>
                <th>Tijd</th>
                <th>Organisatie</th>
                <th>Aanmeldingen</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="shift in openShifts" :key="shift.id" class="clickable" @click="goToOpenShift(shift)">
                <td>{{ shift.function }}</td>
                <td>{{ shift.location }}</td>
                <td>{{ getBeginDate(shift) }}</td>
                <td>{{ getTimeRange(shift) }}</td>
                <td>{{ shift.customer.name }}</td>
                <td>{{ shift.applicationsCount }}</td>
                <td><img :src="require('@/assets/pencil-square.svg')" alt="pencil" /></td>
            </tr>
        </tbody>
    </table>
    <span v-else>Geen open aanvragen beschikbaar</span>
</template>

<script lang="ts">
    import Vue from 'vue';
    import {Component, Prop} from 'vue-property-decorator';
    import {OpenShift} from '@/assets/MarktplaatsInterfaces';

    @Component({})
    export default class ShiftOverview extends Vue {
        @Prop() shifts!: OpenShift[];
        @Prop(Boolean) sortDescending?: boolean;

        private get openShifts(): OpenShift[] {
            return this.sortDescending === true
                ? [...this.shifts].sort((a: OpenShift, b: OpenShift) => b.timeBegin.getTime() - a.timeBegin.getTime())
                : this.shifts;
        }

        private goToOpenShift(openShift: OpenShift): void {
            this.$router.push({name: 'open-shift', params: {id: String(openShift.id), openShift: JSON.stringify(openShift)}});
        }

        private getTimeRange(shift: OpenShift): string {
            const timeBegin: string = shift.timeBegin.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'Europe/Amsterdam'
            });

            const timeEnd: string = shift.timeEnd.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'Europe/Amsterdam'
            });

            return (timeBegin + ' - ' + timeEnd);
        }

        private getBeginDate(openShift: OpenShift): string {
            return openShift.timeBegin.toLocaleDateString().replaceAll('/', '-');
        }
    }
</script>

<style scoped lang="scss">
    .table {
        tbody {
            tr:nth-child(odd) {
                background-color: #cccccc;
            }

            tr:hover {
                opacity: 0.8;
            }
        }
    }

    .clickable {
        cursor: pointer;
    }
</style>