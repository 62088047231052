<template>
    <div></div>
</template>

<script lang="ts">
    import {Vue, Component} from 'vue-property-decorator';
    import {MarketplaceAPIClient} from '@/util/MarketplaceAPIClient';

    @Component({})
    export default class Redirect extends Vue {
        created(): void {
            MarketplaceAPIClient.marketplaceRedirect();
        }
    }
</script>

<style scoped lang="scss">

</style>