<template>
    <div class="flex-container">
        <div class="row">
            <div class="col-12">
                <div class="login-card">
                    <cq-card>
                        <img :src="require('@/assets/rp-logo@2x.png')" alt="Niet gevonden" class="mb-3">

                        <form @submit.prevent="changePassword()">
                            <h1>Wachtwoord wijzigen</h1>

                            <cq-text-input type="password"
                                validate
                                required
                                label="Huidige wachtwoord"
                                v-model="oldPassword" />

                            <cq-text-input type="password"
                                validate
                                required
                                label="Nieuw wachtwoord"
                                v-model="newPassword"
                                minlength="7" />

                            <cq-text-input type="password"
                                validate
                                required
                                label="Nieuw wachtwoord bevestigen"
                                v-model="newPasswordCheck"
                                minlength="7" />

                            <cq-button type="submit"
                                primary
                                label="Wachtwoord wijzigen"
                                class="my-2" />
                        </form>

                        <div class="back-to-login">
                            <a class="clickable" @click="$router.push({name: 'open-shift-overview'})">Terug naar overzicht</a>
                        </div>

                    </cq-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {MarketplaceAPIClient} from '@/util/MarketplaceAPIClient';
    import Vue from 'vue';
    import {Component} from 'vue-property-decorator';
    import {CqButton, CqCard, CqTextInput} from '@cquential/ui';

    @Component({
        components: {
            CqButton,
            CqCard,
            CqTextInput,
        }
    })
    export default class ChangePassword extends Vue {
        private oldPassword: string = '';
        private newPassword: string = '';
        private newPasswordCheck: string = '';

        private changePassword(): void {
            if (this.newPassword === this.newPasswordCheck) {
                MarketplaceAPIClient.changePassword(this.oldPassword, this.newPassword).then(() => {
                    alert('Wachtwoord gewijzigd!');
                    this.oldPassword = '';
                    this.newPassword = '';
                    this.newPasswordCheck = '';
                    this.$router.push({name: 'open-shift-overview'});
                }).catch((error: string) => {
                    alert(error);
                });
            } else {
                alert('Nieuwe wachtwoorden komen niet overeen');
            }
        }
    }
</script>

<style scoped lang="scss">
    .login-card {
        position: relative;
        width: 26rem;
        margin: 0 auto;
        text-align: center;

        img {
            height: 52px;
            margin: 0 auto;
        }

        .back-to-login {
            font-size: 0.875rem;
            margin: 1rem 0 0;
        }
    }

    .clickable {
        cursor: pointer;
    }
</style>