<template>
    <div id="app">
        <header v-if="!(publicPages.includes($route.path))">
            <div class="wrapper">
                <div class="d-flex flex-row justify-content-between no-gutters">
                    <div class="p-2">
                        <div class="logo"></div>
                    </div>

                    <div class="p-2 navigation">
                        <router-link :to="{name: 'open-shift-overview'}">Open aanvragen</router-link>
                        <router-link :to="{name: 'open-shift-history'}">Historie</router-link>
                    </div>

                    <div class="p-2 user-information d-flex flex-row">
                        <div class="userMenu">
                            <span class="currentUser">Ingelogd als {{ username }}</span>
                            <img :src="require('@/assets/justify.svg')" />
                            <div class="userMenuContent">
                                <ul class="userMenuList">
                                    <li><a @click="$router.push({name: 'change-password'})">Wachtwoord wijzigen</a></li>
                                    <li><a @click="$router.push({name: 'login', params: {logout: true}})">Uitloggen</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div id="pageContent">
            <router-view v-on:setUsername="setUsername($event)" />
        </div>

        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <a href="https://www.c-quential.nl" target="_blank">
                            <i class="icon link c-quential white lg"></i>
                            <p><strong>De marktplaats</strong> is een product van <strong>C-quential</strong></p>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script lang="ts">
    import {Vue, Component} from 'vue-property-decorator';
    import {MarketplaceAPIClient} from '@/util/MarketplaceAPIClient';


    @Component({})
    export default class App extends Vue {
        private publicPages: Array<string> = ['/login'];
        private username?: string | null = null;

        updated(): void {
            this.username = MarketplaceAPIClient.getUsername();
        }

        setUsername(username: string): void {
            MarketplaceAPIClient.setUsername(username);
        }
    }

</script>

<style scoped lang="scss">
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: grid;
        grid-template-rows: 3rem 1fr 14rem;
        flex-direction: column;
        background: white;
        height: 100vh;
    }

    header {
        font-weight: normal;
        box-sizing: border-box;
        position: fixed;
        z-index: 9;
        display: block;
        top: 0;
        width: 100vw;
        height: 3rem;
        padding: 0;
        background: white;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
        border-bottom: 2px solid #5bb8ed;

        .logo {
            position: relative;
            width: 7rem;
            height: 2rem;
            background: url("assets/rp-logo@2x.png") no-repeat center center;
            background-size: contain;
        }

        .navigation {
            margin: auto;

            a {
                padding: 0 10px;
            }

            a.router-link-active {
                text-decoration: underline;
            }
        }

        .user-information {
            margin-right: 20px;

            .currentUser {
                margin-right: 10px;
            }
        }
    }

    footer {
        bottom: 0;
        position: fixed;
        z-index: 7;
        box-sizing: border-box;
        font-weight: normal;
        height: 14rem !important;
        width: 100vw;
        overflow: auto;
        padding: 2rem 0;
        background: linear-gradient(90deg, #3b4395 0%, #63c3d1 60%, #3fb498 100%);
        color: #ffffff;
        text-align: center;
        box-shadow: 0 -4px 16px rgba(0, 0, 0, 0.15);
        display: block;

        * {
            font-size: 16px;
        }

        p {
            color: white;
        }

        .icon {
            margin: 1rem 50%;
        }

        a {
            color: #3b4395 !important;
        }
    }

    #pageContent {
        margin: 80px auto 300px auto;
        max-width: 1500px;
    }

    .center {
        margin: auto;
    }

    .userMenu {
        &:hover .userMenuContent {
            display: block;
        }

        .userMenuContent {
            display: none;
            position: absolute;
            background-color: whitesmoke;
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
            padding: 0 5px;
            right: 20px;
            top: 35px;
            border-radius: 8px;

            .userMenuList {
                list-style-type: none;
                margin: 0;
                padding: 5px;

                a:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }

</style>